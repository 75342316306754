<template>
  <div class="row mt-4 credit-card">
    <div class="col-6">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div class="bg-info p-3">
            <h5 class="card-title mb-0 font-weight-bold">Credit Card Data</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <b-form-group id="input-email" label="Email" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    type="email"
                    placeholder="example@gmail.com"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-form-group id="input-ref" label="Ref Number" label-for="ref">
                  <b-form-input
                    id="ref"
                    v-model="form.ref"
                    type="text"
                    placeholder="Enter ref"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12">
                <b-button class="mr-3 text-white" type="submit" variant="info"
                  >Search Data</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "credit",
  data() {
    return {
      // Form: {}
      form: {
        email: "",
        ref: ""
      },
      show: true
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.ref = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>

<style scoped>
.credit-card {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
</style>
